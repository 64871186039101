@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-UltraBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GeistMono';
  src: url('../public/fonts/geist-mono/GeistMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GeistMono';
  src: url('../public/fonts/geist-mono/GeistMono-Medium.ttf') format('truetype');
   font-weight: 500;
  font-style: normal;
}

body,
html {
  height: 100%;
  margin-bottom: 40px;
  font-family: "Geist", sans-serif;
  color: #333;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-syntax-highlighter-line-number {
  color: #646464 !important; /* Override the color */
  margin-right: 10px !important; /* Ensure this matches your desired spacing */
}
