.detail {
  font-size: 1.2rem;
}

.detail a {
  font-weight: 500;
  color: #000000;
  text-decoration: underline;
  padding: 0 2px;
}
