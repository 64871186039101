.rounded-image {
  border-radius: 10px;
}

.rounded-image-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rounded-image-container p {
  text-align: center;
  display: inline;
  margin-top: 0;
  font-size: 15px;
  color: #676666;
}

.rounded-image-container a {
  font-weight: 500;
  color: #878787;
  text-decoration: underline;
  padding: 0 2px;
}
