@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 740px) {
  .container {
    max-width: 740px;
  }
}

@media (min-width: 1080px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 1080px) {
  .container {
    flex-direction: column;
  }

  .main-content {
    padding-top: 0 !important;
  }

  .navbar {
    margin-left: 1.5rem;
    padding-top: 3rem !important;
    padding-bottom: 2rem;
  }

  .nav-items {
    display: none;
    text-align: start;
  }

  .nav-items.active {
    display: block;
  }

  .nav-items a.active {
    display: none;
  }
  .nav-bar-hidden-title {
    display: flex !important;
  }

  .sidebar-small {
    display: flex !important;
    flex-direction: row;
    margin-left: 1rem;
  }
}

@media (max-width: 740px) {
  .blog-detail h1 {
    font-size: 2rem !important;
  }

  .blog-detail h2 {
    font-size: 1.5em !important;
  }

  .blog-detail h3 {
    font-size: 1.2em !important;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: auto; /* Center the container */
}

.main-content {
  flex: 1;
  padding: 20px;
  padding-top: 5rem;
  margin: 0;
  margin-bottom: 6rem;
}

.blog-list-title {
  margin: 0;
}

.blog-list-description {
  margin-top: 0;
  margin-bottom: 32px;
}

.finished-message {
  font-size: 15px;
  color: #00000069;
  margin-top: 40px;
  font-style: italic;
}

.divider {
  padding-top: 20px;
}

.blog-detail h1 {
  font-size: 3rem;
  line-height: normal;
  font-weight: 500;
  letter-spacing: -0.025em;
}

.blog-detail h2 {
  font-weight: 400;
  font-size: 2.25em;
  margin-top: 2rem;
  line-height: normal;
}

.blog-detail h3 {
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 1.5rem;
  line-height: normal;
}
.blog-detail div {
  line-height: 1.6rem;
  font-size: 1rem;
}

/* Nav bar styles */
.navbar {
  top: 0;
  flex: 0; /* Sidebar takes up space, but can grow */
  padding-top: 5rem;
  text-align: end;
  margin-right: 6rem;
}

.sidebar {
  top: 0;
  flex: 0; /* Sidebar takes up space, but can grow */
  padding-top: 5rem;
  text-align: end;
  margin-right: 6rem;
}

.nav-items a {
  display: block;
  text-decoration: none;
  color: #333333a5;
  margin: 5px 0;
  font-size: 18px;
}

.nav-items a:hover,
.nav-items a.active {
  color: var(--color-primary-800);
  text-transform: scale(1.2);
  font-weight: 700;
  font-style: bold;
}

.copyright {
  opacity: 40%;
  margin-top: 10px;
  font-size: 10px;
}

.back-button {
  width: 2rem;
  height: 1.5rem;
  opacity: 30%;
  margin-top: 2rem;
}

.back-button:hover {
  opacity: 100%;
  cursor: pointer;
  transform: scale(1.2);
  transition-duration: 0.2s;
}

.published-date-title {
  font-size: 1rem;
  color: #000000c5;
  margin-top: 2rem;
  font-weight: 200;
  margin-bottom: 0;
}

.published-date {
  font-size: 1rem;
  color: #000000c5;
  margin-top: 0;
  font-weight: 300;
}

.nav-bar-hidden-title {
  display: none;
  font-size: 18px;
  color: #000000;
  flex-wrap: wrap;
  align-items: center;
}

.nav-bar-hidden-title p {
  margin: 0;
  text-align: center;
  margin-right: 10px;
  font-weight: 700;
  font-style: bold;
  font-size: 18px;
}

.nav-bar-hidden-title img {
  align-items: center;
}

.rotate {
  transform: rotate(90deg);
}

.sidebar-small {
  display: none;
  font-size: 18px;
  color: #000000;
  flex-wrap: wrap;
  align-items: center;
}

.published-date-small {
  font-size: 1rem;
  color: #000000c5;
  margin: 0;
  margin-left: 1rem;
  font-weight: 300;
  text-align: center;
}

.back-button-small {
  width: 2rem;
  height: 1.5rem;
  opacity: 30%;
}
