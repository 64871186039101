.post {
  margin-bottom: 20px;
  text-decoration: none;
}

.post h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000;
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

.post:hover h3 {
  transition-property: font-weight;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.post p {
  font-size: 1rem;
  color: #000000;
  margin: 0;
  font-weight: 400;
}

.post-meta {
  font-size: 0.75rem !important;
  color: #838383;
  margin-top: 10px;
}

.post-link {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #838383;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  transition: transform 0.3s ease;
}

.divider {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #838383;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  transition: transform 0.3s ease;
}
