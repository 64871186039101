.socials {
  display: inline-flex;
  margin-top: 15px;
}

.divider {
  width: 6px;
}

.socialsicons {
  opacity: 80%;
  width: 18px;
  height: 18px;
  transition: transform 0.3s;
}
.socialsicons:hover {
  transform: scale(1.25);
}
