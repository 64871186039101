/* Add borders between posts */
.divider {
  padding-top: 20px;
}

.finished-message {
  font-size: 15px;
  color: #00000069;
  margin-top: 40px;
  font-style: italic;
}

.reading-list-title {
  margin: 0;
}

.reading-list-description {
  margin-top: 0;
  margin-bottom: 32px;
}
