@media screen and (max-width: 640px) {
  .intro p {
    font-size: 2rem !important;
  }
  
}

.intro {
  margin-bottom: 4rem;
}

.intro p {
  margin-bottom: 20px;
  font-family: sans;
  font-size: 2.5rem;
  font-family: "Playfair Display", serif;
}

.writing {
  margin-bottom: 40px;
}

.article h3 {
  margin-top: 0;
}

.article p {
  line-height: 1.6;
  margin: 10px 0;
}

.list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-title-section {
  flex-grow: 1;
}

.all-link-section {
  margin-left: auto;
  align-self: center;
  color: #33333389;
}

.all-link {
  font-size: 16px;
  line-height: 24px; /* leading */
  font-weight: 600;
  color: #33333389;
  text-decoration: none; /* Removes underline from the link */
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.all-link:hover {
  color: #333;
  font-weight: 800;
  transition: color 0.3s ease;
}

.reflection {
  margin-bottom: 60px;
}
