.quote-container p {
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  opacity: 0.8;
}

.quote-container {
  border-left-color: #e5e5e5;
  border-left-width: 0.25rem;
  border-left-style: solid;
  padding-left: 1em;
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}
