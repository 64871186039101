.divider {
  height: 1px; /* Adjust the height of the divider as needed */
  background-color: black;
  width: 100%;
}

.navbar {
  display: none;
  top: 0;
  flex: 0; /* Sidebar takes up space, but can grow */
  padding-top: 3rem;
  text-align: end;
}

.copyright {
  opacity: 40%;
  margin-top: 10px;
  font-size: 10px;
}

.nav-items {
  margin-right: 2rem;
}

.nav-items a {
  display: block;
  text-decoration: none;
  color: #333333a5;
  margin: 5px 0;
  font-size: 18px;
}

.nav-items a:hover,
.nav-items a.active {
  color: var(--color-primary-800);
  text-transform: scale(1.2);
  font-weight: 700;
  font-style: bold;
}

@media (max-width: 1080px) {
  .navbar {
    display: block;
  }
}
