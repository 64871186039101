.video-container {
  position: relative;
  border-radius: 5px;
  width: fit-content;
}

.video-container video {
  object-fit: cover;
  border-radius: 10px;
}

.video-controls {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  opacity: 20%;
  width: 1.5rem;
  height: 1.5rem;
}

.video-container img {
  width: 1.5rem;
  height: 1.5rem;
}

.video-controls:hover {
  opacity: 100%;
}
